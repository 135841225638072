<template>
  <div class="report-root">
    <div class="chart-container">
      <component :is="chart" :data="dataList" :region="filterForm.timeRegion" ></component>
    </div>
    <div class="selector-container">
      <el-form ref="filterForm" :model="filterForm" inline :rules="rules">
        <el-form-item label="报表类型" prop="timeRegion">
          <el-select v-model="filterForm.timeRegion" placeholder="选择类型" @change="selectType">
            <el-option value="年"  label="年报"/>
            <el-option value="月"  label="月报"/>
            <el-option value="周" label="周报"/>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" v-if="filterForm.timeRegion!==null" prop="time">
          <el-date-picker v-model="filterForm.time" v-if="filterForm.timeRegion=='年'" placeholder="选择年份" type="year">
          </el-date-picker>
          <el-date-picker v-if="filterForm.timeRegion=='月'" v-model="filterForm.time" placeholder="选择月份" range-separator="To"
        start-placeholder="Start month"
        end-placeholder="End month" type="monthrange">
          </el-date-picker>
          <el-date-picker v-if="filterForm.timeRegion=='周'" v-model="filterForm.time" placeholder="选择周" type="week">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="单位" prop="company">
          <el-select v-model="filterForm.company" placeholder="单位" >
            <el-option-group v-for="[key, value] in Object.entries(userOrgs)" :label="key" :key="key">
              <el-option v-for="org in value" :key="org.org_id" :label="org.org_name" :value="org.org_id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="el-form">
        <el-button type="primary" size="small" @click="submit(refForm)" >查询</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getOrgOverViewByTime } from "network/overview";
import BarChart from "./component/BarChart.vue";
import PieChart from "./component/PieChart.vue";
import { Plus } from "@element-plus/icons-vue";
import { deepCopy } from '../../assets/js/utils/utils';
export default {
  computed: {},
  data() {
    return {
      filterForm: {
        timeRegion: "年",
        time: null,
        company:''
      },
      chart:'PieChart',
      rules:{
        company:[{
            required: true,
            message: "不为空",
            trigger: "change",
          }],
          timeRegion: [{
            required: true,
            message: "不为空",
            trigger: "change",
          }],
          time: [{
            required: true,
            message: "不为空",
            trigger: "change",
          }]
        },
      dataList: []
    };
  },
  components: {
    Plus,
    BarChart,
    PieChart
  },
  computed: {

    refForm(){
      return this.$refs.filterForm
    },
    userAuth() {
      return this.$store.getters.getUserAuth
    },
    userRole() {
      return this.$store.getters.getUserRole
    },
    userOrgs() {
      return this.$store.getters["companies/userCompanies"];
    },

  },
  created(){
    this.selectType(this.filterForm.timeRegion)
  },
  methods: {
    selectType(val){
      this.dataList=[]
      this.filterForm.time=''
      console.log(val)
      if(val=='年')
      this.chart='PieChart'
      else
      this.chart='BarChart'
    },
    chartClick(time,type){
      let date=new Date(time.getTime())
      let region
      switch(type)
      {
        case '年':
          region=this.getTimeRegion([time,new Date(date.setFullYear(date.getFullYear()+1))],'月')
        case '月':
          region=this.getTimeRegion([time,new Date(date.setFullYear(date.getFullYear()+1))],'')
        case '周':
      }
    },
    getTimeRegion(time, type) {
      let date
      if(time instanceof Array)
        date = new Date(time[0].getTime())
        else
        date = new Date(time.getTime()) 
      let res=[]
      switch (type) {
        case '年':
          res.push([date.getFullYear().toString(),time,new Date(date.setFullYear(date.getFullYear() + 1))])
          break
        case '月':
          let e=time[1].getTime()
          while(date.getTime()<=e)
          res.push([date.getFullYear().toString()+"-"+(date.getMonth()+1).toString(),deepCopy(date),new Date(date.setMonth(date.getMonth() + 1))])
          break
        case '周':
          let i=0
          while(i<7)
          {res.push(["星期"+(date.getDay()>0?date.getDay().toString():"日"),deepCopy(date),new Date(date.setDate(date.getDate() + 1))]);i++}
          break
      }
      return res
    },
    submit(refForm){
      if(refForm)
        refForm.validate(valid=>{
          if(valid)
          {
            let org=this.filterForm.company
            let time=this.filterForm.time
            let region=this.getTimeRegion(time,this.filterForm.timeRegion)
            this.getDataList(region,org)
            // getOrgOverViewByTime(time.getTime(),end.getTime(),org).then(
            //   ({data})=>{
            //     for(let [k ,v] of Object.entries(res.data))
            //         {
                        
            //             let b=cache.find(a=>a[0]==k)
            //             if(b)
            //             array.push([b[1],v['qualified'],v['unqualified']])
                        
            //         }this.dataList=array
            //   },e=>{console.log(e.message)}
            // )
          }
          else
          {this.$message.warning("校验不通过")
          refForm.resetFeilds()}
        })
    },
    getDataList(region,org){
      let promise=[]
            for(let item of region){
              promise.push(getOrgOverViewByTime(item[1].getTime(),item[2].getTime(),org).then(
                res=>{return res.data[org]},e=>{return e}
              ))
            }
            Promise.allSettled(promise).then(
              values=>{
                let a=[]
                for(let i in values)
                if(values[i].status=='fulfilled')
                a.push([region[i][0],values[i].value['qualified'],values[i].value['unqualified']])
                else
                console.log(region[i][0]+"服务器请求不成功")
                this.dataList = a
              }
            )
    }
  }
};
</script>
<style lang="less" scoped>
.report-root {
  width: 90vw;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #06237a;

  .chart-container {
    flex: 0 0 66vh;
    height: 66vh;
    width: calc(100% - 2vw);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12) inset;
    background-color: #06237a;
    border-radius: 8px;

    #bar-chart {
      height: 100%;
      width: 100%;
    }
    #pie-chart {
      height: 100%;
      width: 100%;
    }
  }

  .selector-container {
    width: calc(100% - 2vw - 4px);
    flex: 0 0 calc(18vh - 4px);
    height: calc(18vh - 4px);
    border: 2px solid var(--el-border-color-base);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .el-form {
      flex: 0 0 5vh;
      height: 5vh;
      width: calc(100% - 2vw);
      padding-left: 2vw;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

}

.selector-container /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
.selector-container /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
.selector-container /deep/ input {
  color: #f2f2f2;
}
</style>